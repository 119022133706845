<template>
  <div>
    <b-modal dir="rtl" ref="nutritions-edit" id="nutritions-edit" hide-footer title="עדכון רכיבים למוצר" size="md" >
     <div class="d-block text-center">
        <form v-on:submit.prevent="save" enctype="multipart/form-data">
        <div class="row">
          <div class="col-12">
            <p>
              בחר ערך תזונתי להוספה
            </p>
            <v-select :options="nutritions" label="title" dir="rtl" @input="addToList"></v-select>
          </div>
        </div>
        <hr />
        <h2>ערך תזונתיי המוצר</h2>
        <div class="row" v-for="(nutrition,index) in product.nutritions" :key="nutrition.nutrition_id"   v-bind:index="index">
          <div class="col-2 text-center">
            <b-button id="show-btn" variant="danger" size="sm"  v-on:click="remove(index)">X</b-button>
          </div>
          <div class="col-5">
            <div class="form-group">
              <input type="text" placeholder="שם הערך תזונתי" name="title" v-model="nutrition.title"  class="form-control" /><br>
            </div>
          </div>
          <div class="col-5">
            <div class="form-group">
              <input type="text" placeholder="הערך (אופציונלי)" name="value" v-model="nutrition.value"  class="form-control" /><br>
            </div>
          </div>
        </div>
        <input type="submit" value="שמור" class="btn btn-primary" />
         </form>

     </div>
   </b-modal>
  </div>
</template>
<script>
import axios from "axios"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: "ProductIngrideint",
    components:{
      vSelect
    },
    data() {
      return {
        product: {},
        nutritions: [],
        formi: {
          title:'',
          value:''
        }
      }
    },
    methods: {
      remove(index) {
        console.log(index)
        this.product.nutritions.splice(index,1);
      },
      save() {
          this.$refs['nutritions-edit'].hide()
        this.$emit("listenerChild", this.product);
      },
      addToList(value) {
        console.log(value)
        this.product.nutritions.push({
          nutrition_id: value.nutrition_id,
          title: value.title,
          value: '',
        })
      },
      async edit(p){
        this.product = p;
        await this.getNutritionsList();
      },
      async getNutritionsList() {
        axios.get("/api/nutritions").then((response) => {
          this.nutritions = response.data;
        }).catch((errors) => {
          console.log(errors)
        })
      }
    }
  }

</script>
