<template>
  <div>
    <h2>ערכים תזונתיים</h2>
    <p>מחובר כ: {{ user.name }} |       <a href="#" v-on:click="logout">התנתק</a></p>
    <div class="container">
      <div class="row">
        <div class="col-12">
            <b-button id="show-btn" variant="danger" class="m-1" :disabled="deleteItems.length === 0" @click="del"><font-awesome-icon icon="minus" />  מחק מסומנים</b-button>
            <b-button id="show-btn" variant="primary" class="m-1" @click="showModal"><font-awesome-icon icon="plus" /> הוסף ערך תזונתי</b-button>
            <hr />
            <div id="nutrition">
              <v-client-table ref="myTable" :columns="nutritions.columns" v-model="nutritions.data" :options="nutritions.options">
                <div slot="select" slot-scope="{row}">
                  <input type="checkbox" v-model="deleteItems" :value="row.nutrition_id" v-on:input="checkboxToggle(row.nutrition_id)"   />
                </div>
                 <div slot="title" slot-scope="{row, update, setEditing, isEditing, revertValue}">
                   <span @click="setEditing(true)" v-if="!isEditing()">
                     <a>{{row.title}}</a>
                   </span>
                   <span v-else>
                     <input type="text" v-model="row.title" class="form-control">
                     <button type="button" class="btn btn-info btn-xs m-1" @click="save(row);update(row.title); setEditing(false)">שמור</button>
                     <button type="button" class="btn btn-default btn-xs m-1" @click="revertValue(); setEditing(false)">ביטול</button>
                   </span>
                 </div>
               </v-client-table>

            </div>
        </div>
      </div>
    </div>
    <b-modal ref="add-nutrition" hide-footer title="ערך תזונתי חדש" size="sm" >
     <div class="d-block text-center">
      <form v-on:submit.prevent="create" enctype="multipart/form-data">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="form-group">
            <label>שם הערך התזונתי</label>
            <input type="text" name="product_name" id="product_name" v-model="form.name"  class="form-control" /><br>
          </div>
        </div>
      </div>
         <input type="submit" value="שמור" class="btn btn-primary" />
       </form>
       </div>
   </b-modal>
  </div>
</template>
<script>
import axios from "axios"
import router from "../router"


export default {
    name: "Nutrition",
    components:{
    },
    data() {
      return {
        deleteItems: [],
        nutritions: {
          el: "#nutrition",
          data: [],
          columns: ['select','title', 'nutrition_id'],
          options: {
            headings: {
              select: "בחר",
              nutrition_id: "קוד",
              title: "שם"
            },
            editableColumns:['title'],
            sortable: ['title'],
            filterable: ['title'],
            uniqueKey: "nutrition_id",
            resizableColumns: true,
            addSortedClassToCells: true,
            highlightMatches:true
          },
        },
        form: {
          title: ''
        },
        user: {
          name: "Jesse"
        }
      }
    },
    methods: {
      checkboxToggle(id) {
        if (!this.deleteItems.includes(id)) {
          this.deleteItems.push(id);
        } else {
          this.deleteItems = this.deleteItems.filter((l) => l !== id);
        }
        console.log(this.deleteItems.length)
      },
      del() {
        this.$swal.fire({
          title: 'אישור מחיקת ערכים',
          text: "לא תוכל לשחזר ערכים אלו לאחר שימחקו",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'כן, מחק',
          cancelButtonText: 'ביטול'
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            this.deleteItems.map(function(value) {
               formData.append('nutrition_id[]',value);
           });


             axios.post('/api/deletenutritions',formData).then((response)=>{
              if (response.data.status=="ok") {
                this.$swal.fire(
                  'נמחק!',
                  'הערכים נמחקו בהצלחה',
                  'success'
                );

                  this.getNutritionsList()

              }
            });

          }
        })
      },
      async save(product) {
        const formData = new FormData();
        formData.append('title',product.title);
        formData.append('nutrition_id',product.nutrition_id);
        try{
          await axios.post('/api/updatenutrition',formData).then((response)=>{
            if (response.data.status=="ok") {
            //  this.getNutritionsList()
            }
          });
        }
        catch(err){
          console.log(err);
          this.message = err.response.data.error
        }
      },
      async create() {
        const formData = new FormData();
        formData.append('title',this.form.name);
        try{
          await axios.post('/api/createnutrition',formData).then((response)=>{
            if (response.data.status=="ok") {
              this.hideModal();
              this.getNutritionsList()
            }
          });
        }
        catch(err){
          console.log(err);
          this.message = err.response.data.error
        }
      },
      showModal() {
        this.$refs['add-nutrition'].show()
      },
      hideModal() {
        this.$refs['add-nutrition'].hide()
      },
      logout: function (e) {
          e.preventDefault();
          axios.get("/api/logout").then(() => {
            router.push("/")
          })
      },
      getUserData: function() {
        let self = this
        axios.get("/api/user").then((response) => {
          self.$set(this, "user", response.data.user)
        }).catch((errors) => {
          console.log(errors)
          router.push("/")
        })
      },
      async getNutritionsList() {
        axios.get("/api/nutritions").then((response) => {
          this.nutritions.data = response.data;
        }).catch((errors) => {
          console.log(errors)
        })
      }
    },
    computed: {
    //  isEmpty: ({ deleteItems }) => items.length === 0
    },
    async mounted() {
      this.getUserData()
      this.$refs.myTable.setLoadingState(true);
      const {data} = await axios.get('api/nutritions');
      this.nutritions.data = data;
      this.$refs.myTable.setLoadingState(false);
    }
}

</script>
