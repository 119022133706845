<template>
  <div>
    <b-modal dir="rtl" ref="ingridiends-edit" id="ingridiends-edit" hide-footer title="עדכון רכיבים למוצר" size="md" >
     <div class="d-block text-center">
        <form v-on:submit.prevent="save" enctype="multipart/form-data">
        <div class="row">
          <div class="col-12">
            <p>
              בחר מרכיב להוספה
            </p>
            <v-select :options="ingridients" label="title" dir="rtl" @input="addToList"></v-select>
          </div>
        </div>
        <hr />
        <h2>מרכיבי המוצר</h2>
        <div class="row" v-for="(ingridient,index) in product.ingridients" :key="ingridient.ingredient_id"   v-bind:index="index">
          <div class="col-2 text-center">
            <b-button id="show-btn" variant="danger" size="sm"  v-on:click="remove(index)">X</b-button>
          </div>
          <div class="col-5">
            <div class="form-group">
              <input type="text" placeholder="שם המרכיב" name="title" v-model="ingridient.title"  class="form-control" /><br>
            </div>
          </div>
          <div class="col-5">
            <div class="form-group">
              <input type="text" placeholder="הערך (אופציונלי)" name="value" v-model="ingridient.value"  class="form-control" /><br>
            </div>
          </div>
        </div>
        <input type="submit" value="שמור" class="btn btn-primary" />
         </form>

     </div>
   </b-modal>
  </div>
</template>
<script>
import axios from "axios"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: "ProductIngrideint",
    components:{
      vSelect
    },
    data() {
      return {
        product: {},
        ingridients: [],
        formi: {
          title:'',
          value:''
        }
      }
    },
    methods: {
      remove(index) {
        console.log(index)
        this.product.ingridients.splice(index,1);
      },
      save() {
          this.$refs['ingridiends-edit'].hide()
        this.$emit("listenerChild", this.product);
      },
      addToList(value) {
        console.log(value)
        this.product.ingridients.push({
          ingredient_id: value.ingredient_id,
          title: value.title,
          value: '',
        })
      },
      async edit(p){
        this.product = p;
        await this.getIngridientsList();
      },
      async getIngridientsList() {
        axios.get("/api/ingridients").then((response) => {
          this.ingridients = response.data;
        }).catch((errors) => {
          console.log(errors)
        })
      }
    }
  }

</script>
