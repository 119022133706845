<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <h2>כניסה למערכת ארומה</h2>
        <form v-on:submit="login">
        <div class="form-group">
          <label>דואר אלקטרוני</label>
          <input type="email" name="email" id="email"  class="form-control" /><br>
        </div>
        <div class="form-group">
          <label>סיסמה</label>
          <input type="password" name="password" id="pasword"  class="form-control" /><br>
        </div>
          <input type="submit" value="כניסה" class="btn btn-primary" />
        </form>
        </div>
    </div>
  </div>
</template>

<script>
    import router from "../router"
    import axios from "axios"

    export default {
        name: "Login",

        methods: {
            login: function(e){
              e.preventDefault();
              let me = this;
              let email = e.target.elements.email.value
              let password = e.target.elements.password.value
              let login = function(){
                  let data = {
                      email: email,
                      password: password
                  }
                  axios.post("/api/login", data).then(() => {
                  //  console.log("Logged in")
                    router.push("/Products")
                  }).catch(() => {
                    me.$swal('אופס','שם המשתמש והסיסמה שגויים. ניתן לנסות שוב','error');
                  })
                }
                login()
            },

        }
    }
</script>
