<template>
    <div>
        <h2>מוצרים</h2>
        <p>מחובר כ: {{ user.name }} |       <a href="#" v-on:click="logout">התנתק</a></p>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p>
                <b-button id="show-btn" variant="primary" v-b-modal.add-product v-on:click="addProduct()"><font-awesome-icon icon="plus" /> הוסף מוצר</b-button>
              </p>
            </div>
          </div>
          <div class="row">
              <b-card
                :title="product.title"
                :img-src="product.image"
                img-alt="Image"
                img-class="product_image"
                img-top
                tag="article"
                class="col-4 mb-2"
                v-for="product in products" :key="product.id"
              >
                <b-card-text>
                  <p>מרכיבים:
                    <span v-for="ingridient in product.ingridients" :key="ingridient.ingredient_id" class="d-inline-block p-2">{{ingridient.title}}</span>
                    <b-button href="#" variant="outline-primary" v-b-modal.ingridiends-edit  v-on:click="$refs.productsIngrideints.edit(product)"><font-awesome-icon icon="edit" /></b-button></p>
                  <p>ערכים תזונתיים: <span v-for="nutrition in product.nutritions" :key="nutrition.nutrition_id" class="d-inline-block p-2">{{nutrition.title}} {{nutrition.value}}</span> <b-button href="#" variant="outline-primary" v-b-modal.nutritions-edit  v-on:click="$refs.productsNutritions.edit(product)"><font-awesome-icon icon="edit" /></b-button></p>
                </b-card-text>
                <div>
                  <b-button href="#" variant="outline-primary" v-b-modal.add-product  v-on:click="editProduct(product)">עריכה</b-button>
                  <b-button href="#" variant="outline-danger"  v-on:click="del(product)">מחיקה</b-button>
                </div>
              </b-card>
          </div>
        </div>
        <ProductIngrideint ref="productsIngrideints"  v-on:listenerChild="listenerChild"></ProductIngrideint>
        <ProductNutrition ref="productsNutritions"  v-on:listenerChild="listenerChild"></ProductNutrition>
        <b-modal ref="add-product" id="add-product" hide-footer title="מוצר חדש" size="xl" >
         <div class="d-block text-center">
          <div v-if="!edit">
            <form v-on:submit.prevent="create" enctype="multipart/form-data">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>שם המוצר</label>
                  <input type="text" name="product_name" id="product_name"  v-model="form.title"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>תמונה</label>
                  <input class="form-control" type="file" ref="file" accept="image/png, image/gif, image/jpeg" @change="onSelect" />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>יחידת מידה</label>
                  <select name="product_unit" id="product_unit"  v-model="form.unit"  class="form-control">
                    <option value="g">גרם</option>
                    <option value="ml">מ״ל</option>
                  </select><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>משקל המנה </label>
                  <input type="text" name="product_weight" id="product_weight"  v-model="form.weight"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>תיאור המנה</label>
                  <input type="text" name="product_description" id="product_description"  v-model="form.description"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>טבעוני</label>
                  <select name="product_vegan" id="product_vegan"  v-model="form.vegan"  class="form-control">
                    <option value="N">לא</option>
                    <option value="Y">כן</option>
                  </select><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>ניקוד שומרי משקל</label>
                  <input type="text" name="product_points" id="product_points"  v-model="form.points"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>מידע אלרגני</label>
                  <input type="text" name="products_allergans" id="products_allergans"  v-model="form.allergans"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>טקסט שימו לב</label>
                  <input type="text" name="products_notice" id="products_notice"  v-model="form.notice"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>מידע נוסף</label>
                  <input type="text" name="products_additional" id="products_additional"  v-model="productEdit.additional"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>קוד דוריקס</label>
                  <input type="text" name="dorix_id" id="dorix_id"  v-model="productEdit.dorix_id"  class="form-control" /><br>
                </div>
              </div>
            </div>
               <input type="submit" value="שמור" class="btn btn-primary" />
             </form>
           </div>
          <div v-else>
            <form v-on:submit.prevent="savePChange" enctype="multipart/form-data">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>שם המוצר</label>
                  <input type="text" name="product_name" id="product_name_edit" v-model="productEdit.title"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>תמונה</label>
                  <input class="form-control" id="edit_image" accept="image/png, image/gif, image/jpeg" type="file" ref="file" @change="onSelect" />
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>יחידת מידה</label>
                  <select name="product_unit" id="product_unit"  v-model="productEdit.unit"  class="form-control">
                    <option value="g">גרם</option>
                    <option value="ml">מ״ל</option>
                  </select><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>משקל המנה </label>
                  <input type="text" name="product_weight" id="product_weight"  v-model="productEdit.weight"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>תיאור המנה</label>
                  <input type="text" name="product_description" id="product_description"  v-model="productEdit.description"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>טבעוני</label>
                  <select name="product_vegan" id="product_vegan"  v-model="productEdit.vegan"  class="form-control">
                    <option value="N">לא</option>
                    <option value="Y">כן</option>
                  </select><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>ניקוד שומרי משקל</label>
                  <input type="text" name="product_points" id="product_points"  v-model="productEdit.points"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>מידע אלרגני</label>
                  <input type="text" name="products_allergans" id="products_allergans"  v-model="productEdit.allergans"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>טקסט שימו לב</label>
                  <input type="text" name="products_notice" id="products_notice"  v-model="productEdit.notice"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>מידע נוסף</label>
                  <input type="text" name="products_additional" id="products_additional"  v-model="productEdit.additional"  class="form-control" /><br>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>קוד דוריקס</label>
                  <input type="text" name="dorix_id" id="dorix_id"  v-model="productEdit.dorix_id"  class="form-control" /><br>
                </div>
              </div>
            </div>
            <input type="submit" value="שמור" class="btn btn-primary" />
            </form>
          </div>
         </div>
       </b-modal>
    </div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus,faMinus,faEdit } from '@fortawesome/free-solid-svg-icons'
library.add(faPlus)
library.add(faMinus)
library.add(faEdit)
import axios from "axios"
import router from "../router"
import ProductIngrideint from "@/components/ProductIngrideint"
import ProductNutrition from "@/components/ProductNutrition"

export default {
    name: "Products",
    components:{
      ProductIngrideint,
      ProductNutrition
    },
    data() {
      return {
        products:[],
        ingridients:[],
        nutritions:[],
        form: {
          title: '',
          description: '',
          unit: '',
          weight: '',
          vegan: '',
          points: '',
          allergans: '',
          notice: '',
          ingridients: [],
          nutritions: [],
          additional: [],
          dorix_id: []
        },
        productEdit: '',
        user: {
          name: "Jesse"
        },
        edit: false,
      }
    },
    methods: {
      listenerChild(product) {
        this.saveProduct(product)

      },
      del(product) {

        this.$swal.fire({
          title: 'אישור מחיקת מוצר',
          text: "לא תוכל לשחזר את המוצר לאחרמחיקתו",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'כן, מחק',
          cancelButtonText: 'ביטול'
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            formData.append('id',product.id);
             axios.post('/api/deleteproduct',formData).then((response)=>{
              if (response.data.status=="ok") {
                this.$swal.fire(
                  'נמחק!',
                  'המוצר נמחק בהצלחה',
                  'success'
                )
                const index = this.products.findIndex(pr => pr.id === response.data.data.id)
                if (~index)
                  this.products.splice(index, 1)
              }
            });
          }
        })
      },
      addProduct() {
          this.edit = false
      },
      editProduct(p) {
        this.edit = true;
        this.productEdit = p;
        this.file = "";
      },
      async saveProduct(p) {
        const formData = new FormData();
        formData.append('id',p.id);
        formData.append('title',p.title);
        formData.append('image',p.image);
        formData.append('description',p.description);
        formData.append('unit',p.unit);
        formData.append('weight',p.weight);
        formData.append('vegan',p.vegan);
        formData.append('points',p.points);
        formData.append('allergans',p.allergans);
        formData.append('notice',p.notice);
        formData.append('ingridients',JSON.stringify(p.ingridients));
        formData.append('nutritions',JSON.stringify(p.nutritions));
        formData.append('additional',JSON.stringify(p.additional));
        formData.append('dorix_id',JSON.stringify(p.dorix_id));
        try{
          await axios.post('/api/updateproduct',formData).then(()=>{});
        }
        catch(err){
          console.log(err);
          this.message = err.response.data.error
        }
      },
      onSelect(){
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        const file = this.$refs.file.files[0];
        this.file = file;
        if(!allowedTypes.includes(file.type)){
          this.$swal('אופס','יש להזין תמונה בלבד','error')
        }
        if(file.size>5000000){
          this.$swal('אופס','הקובץ גדול מדי. יש להזין קובץ במשקל של עד 5MB','error')
        }
      },
      async create() {
        const formData = new FormData();
        formData.append('file',this.file);
        formData.append('title',this.form.title);
        formData.append('description',this.form.description);
        formData.append('unit',this.form.unit);
        formData.append('weight',this.form.weight);
        formData.append('vegan',this.form.vegan);
        formData.append('points',this.form.points);
        formData.append('allergans',this.form.allergans);
        formData.append('notice',this.form.notice);
        formData.append('additional',this.form.additional);
        formData.append('dorix_id',this.form.dorix_id);
        try{
          await axios.post('/api/createproduct',formData).then((response)=>{
            if (response.data.status=="ok") {
              this.hideModal();
              this.getProducts()
            }
          });
        }
        catch(err){
          this.message = err.response.data.error
        }
      },
      async savePChange() {
        const formData = new FormData();
        console.log(this.file)
        if(typeof this.file == "object") {
          formData.append('file',this.file);
        }
        formData.append('image',this.productEdit.image);
        formData.append('title',this.productEdit.title);
        formData.append('id',this.productEdit.id);
        formData.append('ingridients',JSON.stringify(this.productEdit.ingridients));
        formData.append('nutritions',JSON.stringify(this.productEdit.nutritions));
        formData.append('description',this.productEdit.description);
        formData.append('unit',this.productEdit.unit);
        formData.append('weight',this.productEdit.weight);
        formData.append('vegan',this.productEdit.vegan);
        formData.append('points',this.productEdit.points);
        formData.append('allergans',this.productEdit.allergans);
        formData.append('notice',this.productEdit.notice);
        formData.append('additional',this.productEdit.additional);
        formData.append('dorix_id',this.productEdit.dorix_id);
        try{
          await axios.post('/api/updateproduct',formData).then((response)=>{
            if (response.data.status=="ok") {
              this.hideModal();
              this.getProducts()
            }
          });
        }
        catch(err){
          this.message = err.response.data.error
        }
      },
      showModal() {
        this.$refs['add-product'].show()
      },
      hideModal() {
        this.$refs['add-product'].hide()
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['add-product'].toggle('#toggle-btn')
      },
      logout: function (e) {
          e.preventDefault();
          axios.get("/api/logout").then(() => {
            router.push("/")
          })
      },
      getUserData: function() {
        let self = this
        axios.get("/api/user").then((response) => {
          self.$set(this, "user", response.data.user)
        }).catch((errors) => {
          console.log(errors)
          router.push("/")
        })
      },
      getProducts() {
        this.products = [];
        axios.get("/api/products").then((response) => {
          response.data.forEach((item) => {
            this.products.push(item)
          });
        }).catch((errors) => {
            console.log(errors)
        })
      },
      async getIngridientsList() {
        axios.get("/api/ingridients").then((response) => {
          this.ingridients = response.data;
        }).catch((errors) => {
          console.log(errors)
        })
      },
      async getNutritionsList() {
        axios.get("/api/nutritions").then((response) => {
          this.nutritions = response.data;
        }).catch((errors) => {
          console.log(errors)
        })
      }
    },
    mounted() {
        this.getUserData()
        this.getProducts()
    }
}

</script>
<style>
.card-img-top {
  height:275px;
}
</style>
