var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("ערכים תזונתיים")]),_c('p',[_vm._v("מחובר כ: "+_vm._s(_vm.user.name)+" | "),_c('a',{attrs:{"href":"#"},on:{"click":_vm.logout}},[_vm._v("התנתק")])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"m-1",attrs:{"id":"show-btn","variant":"danger","disabled":_vm.deleteItems.length === 0},on:{"click":_vm.del}},[_c('font-awesome-icon',{attrs:{"icon":"minus"}}),_vm._v(" מחק מסומנים")],1),_c('b-button',{staticClass:"m-1",attrs:{"id":"show-btn","variant":"primary"},on:{"click":_vm.showModal}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" הוסף ערך תזונתי")],1),_c('hr'),_c('div',{attrs:{"id":"nutrition"}},[_c('v-client-table',{ref:"myTable",attrs:{"columns":_vm.nutritions.columns,"options":_vm.nutritions.options},scopedSlots:_vm._u([{key:"select",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.deleteItems),expression:"deleteItems"}],attrs:{"type":"checkbox"},domProps:{"value":row.nutrition_id,"checked":Array.isArray(_vm.deleteItems)?_vm._i(_vm.deleteItems,row.nutrition_id)>-1:(_vm.deleteItems)},on:{"input":function($event){return _vm.checkboxToggle(row.nutrition_id)},"change":function($event){var $$a=_vm.deleteItems,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=row.nutrition_id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.deleteItems=$$a.concat([$$v]))}else{$$i>-1&&(_vm.deleteItems=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.deleteItems=$$c}}}})])}},{key:"title",fn:function(ref){
var row = ref.row;
var update = ref.update;
var setEditing = ref.setEditing;
var isEditing = ref.isEditing;
var revertValue = ref.revertValue;
return _c('div',{},[(!isEditing())?_c('span',{on:{"click":function($event){return setEditing(true)}}},[_c('a',[_vm._v(_vm._s(row.title))])]):_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.title),expression:"row.title"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(row.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "title", $event.target.value)}}}),_c('button',{staticClass:"btn btn-info btn-xs m-1",attrs:{"type":"button"},on:{"click":function($event){_vm.save(row);update(row.title); setEditing(false)}}},[_vm._v("שמור")]),_c('button',{staticClass:"btn btn-default btn-xs m-1",attrs:{"type":"button"},on:{"click":function($event){revertValue(); setEditing(false)}}},[_vm._v("ביטול")])])])}}]),model:{value:(_vm.nutritions.data),callback:function ($$v) {_vm.$set(_vm.nutritions, "data", $$v)},expression:"nutritions.data"}})],1)],1)])]),_c('b-modal',{ref:"add-nutrition",attrs:{"hide-footer":"","title":"ערך תזונתי חדש","size":"sm"}},[_c('div',{staticClass:"d-block text-center"},[_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("שם הערך התזונתי")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",attrs:{"type":"text","name":"product_name","id":"product_name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('br')])])]),_c('input',{staticClass:"btn btn-primary",attrs:{"type":"submit","value":"שמור"}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }