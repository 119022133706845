import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/Login"
import Products from "@/components/Products"
import Nutrition from "@/components/Nutrition"
import Ingridients from "@/components/Ingridients"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/nutrition",
    name: "Nutrition Facts",
    component: Nutrition
  },
  {
    path: "/ingridients",
    name: "Ingridients",
    component: Ingridients
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
